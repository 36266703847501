import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ContentContainer from "../components/content-container/content-container";
import Quote from "../components/quote/quote";
import ContactInformation from "../components/contact-information/contact-information";

const useStyles = makeStyles({
  container: {
    margin: "auto",
    width: "600px",
    "@media (max-width: 700px)": {
      width: "100%",
    },
  },
});

const ContactUsPage = () => {
  const classes = useStyles();

  return (
    <>
      <Layout>
        <SEO title="Contact Us" />
        <ContentContainer>
          <div className={classes.container}>
            <ContactInformation />
            <Quote />
          </div>
        </ContentContainer>
      </Layout>
    </>
  );
};

export default ContactUsPage;
