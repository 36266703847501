import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { useState } from "react";
import MaskedInput from "react-text-mask";
import { Button, InputLabel, FormControl, OutlinedInput, TextField } from "@material-ui/core";
import { ContentContainer, Form, Field, ButtonContainer } from "./quote-elements";
import Border from "../border/border";

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      /* eslint-disable react/jsx-props-no-spreading */
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={"\u2000"}
      showMask={false}
      label="Phone"
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const Quote = () => {
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  return (
    <>
      <ContentContainer>
        <Border />
        <Typography variant="h1" component="h1">
          Free Quote
        </Typography>
        <Typography variant="subtitle1" component="p">
          Fill out the form below to get a free quote. After we recieve your inquiry, we will reach
          out to you!
        </Typography>
        <Form name="quote" method="POST" data-netlify="true">
          <Field>
            <TextField name="first" label="First Name" variant="outlined" required fullWidth />
          </Field>
          <Field>
            <TextField name="last" label="Last Name" variant="outlined" required fullWidth />
          </Field>
          <Field>
            <FormControl fullWidth>
              <InputLabel htmlFor="formatted-text-mask-input" required variant="outlined">
                Phone Number
              </InputLabel>
              <OutlinedInput
                id="formatted-text-mask-input"
                value={phoneNumber}
                onChange={handleChange}
                name="phone"
                label="Phone Number"
                required
                inputComponent={TextMaskCustom}
              />
            </FormControl>
          </Field>
          <Field>
            <TextField type="email" name="email" label="Email" variant="outlined" fullWidth />
          </Field>
          <Field>
            <TextField
              id="outlined-multiline-static"
              label="Description"
              name="description"
              multiline
              rows={10}
              defaultValue=""
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 200 }}
            />
          </Field>
          <ButtonContainer>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit
            </Button>
          </ButtonContainer>
          <input type="hidden" name="form-name" value="quote" />
        </Form>
      </ContentContainer>
    </>
  );
};

export default Quote;
