import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { IconContext } from "react-icons/lib";
import Border from "../border/border";
import ContentContainer from "./contact-information-elements";

const useStyles = makeStyles({
  contactInformation: {
    color: "#51b53f",
    fontSize: "1.375rem",
    fontWeight: 600,
    lineHeight: "26px",
    marginBottom: "24px",
    "@media (max-width: 500px)": {
      fontSize: "1.15rem",
    },
    "@media (max-width: 350px)": {
      fontSize: "0.9rem",
    },
  },
  subtitle: {
    paddingBottom: 24,
  },
});

const ContactInformation = () => {
  const classes = useStyles();

  return (
    <>
      <IconContext.Provider value={{ size: "100%", color: "#3A76AA" }}>
        <ContentContainer>
          <Border />
          <Typography variant="h1" component="h1">
            Get In Touch
          </Typography>
          <Typography variant="subtitle1" component="p" className={classes.subtitle}>
            Located in Texas but offer services nationwide! Having a question? Help is available
            24/7.
          </Typography>
          <Typography component="p" className={classes.contactInformation}>
            📱 (214) 778-2352
          </Typography>
          <Typography component="p" className={classes.contactInformation}>
            📧 moveit@brotherbearmovers.com
          </Typography>
          <Typography component="p" className={classes.contactInformation}>
            📍 Dallas, TX | Austin, TX
          </Typography>
        </ContentContainer>
      </IconContext.Provider>
    </>
  );
};

export default ContactInformation;
