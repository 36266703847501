import styled from "styled-components";

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 0;
  width: 100%;
`;

export default ContentContainer;
