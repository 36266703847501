import styled from "styled-components";

export const ContentContainer = styled.div`
  // width: 50%;

  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;

export const Form = styled.form`
  padding: 32px 0;
`;

export const Field = styled.div`
  display: flex;
  padding: 8px 0;
`;

export const ButtonContainer = styled.div`
  padding-top: 24px;
  width: 100%;
`;
